/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as style from "./ModalStyle";
import { Layer } from "grommet";
import CloseButton from "../CloseButton";
import {MyButton} from "../../lib/utils";
import {Close} from "grommet-icons";
import * as gtag from "../../lib/gtag";
import {BsChevronRight} from "react-icons/bs";
import React from "react";

const centeredStyle = {
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'center', 
  overflowY: 'auto'
};


export const Modal = ({ children, onModalHide = () => null, footer = "", cstyle = {}, shouldBeCentered = false, showCross=true , showSkip=false,bstyle={},
                      permanent=false, alwaysFullscreen=false, animated=false}) => {
  const onEsc = () => {
    onModalHide();
  };

  const onClickOutside = () => {
    if(!permanent) {
      onModalHide();
    }
  }

  const onClick = () => {
    if(!permanent) {
      onModalHide();
    }
  }

  return (
    <Layer css={animated ? null : style.layerStyle} responsive={true} onEsc={onEsc} onClickOutside={onClickOutside} onClick={onClick} full={alwaysFullscreen}>

      <div css={style.mainContainer} className="p-3" style={cstyle}>


        <div css={style.modalHeader}>
          { showCross && (<CloseButton onClose={onEsc} />) }
          { showSkip && (      <div style={{minHeight: "40px"}}>
            <MyButton
                id="CLOSE_BUTTON"
                icon={<BsChevronRight size={32}/>}
                margin="small"
                onClick={e => {
                  props.onClose(e);
                  gtag.event({
                    action: "close",
                    category: "GENERIC",
                    label: ""
                  });
                  e.stopPropagation();
                }}
            /></div>) }

        </div>

          <div css={style.modalContent} style={shouldBeCentered ? centeredStyle: {}}>
            {children}
          </div>

          <div css={style.modalFooter}>{footer}</div>
      </div>
        <div css={style.backgroundFiligraine}>
        </div>
        <div css={style.background} style={bstyle}>
        </div>
    </Layer>
  );
};
