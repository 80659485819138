import React from "react";
import { Box, Button, Image, Text } from "grommet";
import * as style from "./IconButtonStyle";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

export const IconButton = ({
  children = null,
  imgSrc = null,
  onClick = () => null,
  bsize = "auto",
  label = "",
  disabled = false,
  show = true,
  gap = "0px",
  labelSize = "xsmall",
  labelPosition = "bottom",
  halo = false,
  bgColor = "transparent",
}) => {
  const getIcon = () => {
    if (imgSrc === null && children === null)
      return <div style={{ height: bsize, width: bsize }}></div>;
    if (children) return children;
    else
      return <Image src={imgSrc} height={bsize + "px"} width={bsize + "px"} />;
  };

  const getMainContainerStyle = () => {
    let resu = [style.mainContainer];

    if (disabled) {
      resu.push(style.disabled);
      return resu;
    }

    if (onClick) resu.push(style.pointer);

    if (halo && bgColor === "transparent") {
      resu.push(style.halo); // Apply halo effect when "halo" prop is true
    } else if (bgColor !== "transparent") {
      resu.push(
        css`
          padding: 8px;
        `
      );
    }

    return resu;
  };

  const getImgContainerStyle = () => {
    let resu = [style.imgContainer];

    if (bgColor !== "transparent") {
      resu.push(style.bgColor(bgColor, bsize));

      if (halo) {
        resu.push(style.halo); // Apply halo effect when "halo" prop is true
      }
    }

    return resu;
  };

  const onButtonClick = () => {
    if (!disabled) onClick();
  };

  if (!show) return "";

  const labelBottomLayout = (
    <div onClick={onButtonClick} css={getMainContainerStyle()}>
      <div css={getImgContainerStyle()}>{getIcon()}</div>
      {gap && <div style={{ paddingTop: gap }}></div>}
      <div css={style.labelContainer}>
        <Text
          className="HelveticaNeue-BdCn"
          style={{ textAlign: "center" }}
          size={labelSize}
        >
          {label}
        </Text>
      </div>
    </div>
  );

  const labelLeftLayout = (
    <div onClick={onButtonClick} css={getMainContainerStyle()}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Text
            className="HelveticaNeue-BdCn"
            style={{ textAlign: "center", marginRight: "10px" }}
            size={labelSize}
          >
            {label}
          </Text>
        </div>
        <div css={style.imgContainer}>{getIcon()}</div>
      </div>
    </div>
  );

  if (labelPosition === "bottom") return labelBottomLayout;

  if (labelPosition === "left") return labelLeftLayout;
};
