import { css } from "@emotion/react";
import { ccBackground } from "../../pages/_app";

export const backgroundFiligraine = css`
        z-index: -1;

    opacity: 0.02;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: url('/static/img/logo-daredare.svg');
    background-repeat: no-repeat;
     background-position: right 93% bottom 32%;
    background-size: 175%;
    
      @media only screen and (max-width: 768px) {
         background-position: right 71% bottom 83%;
    background-size: 300%;

  }
`;

export const background = css`

  @media only screen and (max-width: 768px) {
    border-radius: 0px;
      }
      
          border-radius: 24px;


    z-index: -2;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: ${ccBackground};
`;

export const layerStyle = css`
    -webkit-animation: none;
    animation: none;
  `;

export const mainContainer = css`

  padding-bottom: env(safe-area-inset-bottom)!important;
  
  @media only screen and (max-width: 768px) {
    border-radius: 0px;
    min-width: 100%;
  }
  
  max-width: 624px;
  min-width: 624px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
    border-radius: 24px;

`;

export const modalHeader = css`
  flex: 0 0 30px;
  display: flex;
  justify-content: flex-end;
`;

export const modalFooter = css`
  flex: 0 0 100px;
  display: flex;
  justify-content: flex-start;
  min-height: 100px;
`;

export const modalContent = css`
  margin-left: 36px;
  margin-right: 36px;
  max-height: 73vh;
  flex: 1 1 100%;
`;
